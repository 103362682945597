if (window.location.href.includes("vacancies")) {
  window.addInViewClass = () => {
    document
      .querySelectorAll(".anim__line, .scaleX-left__trigger, .fade-in__trigger")
      .forEach((trigger) => {
        trigger.classList.add("in-view");
      });
  };
}

function addInViewClass(trigger) {
  trigger.classList.add("in-view");
}

const animTriggers = document.querySelectorAll(
  ".anim__line, .fade-in__trigger, .scaleX-left__trigger, .scaleX-right__trigger"
);
const _textLines = document.querySelectorAll(".marquee_with-text");

window.addEventListener("scroll", () => {
  animTriggers.forEach((trigger) => {
    if (trigger.getBoundingClientRect().top < window.innerHeight - 15) {
      addInViewClass(trigger);
    }
  });

  _textLines.forEach((target) => {
    if (target.classList.contains("reversed")) {
      target.style.transform = `translateX(-${window.scrollY / 5}px)`;
    } else {
      target.style.transform = `translateX(${window.scrollY / 5}px)`;
    }
  });
});

// Header changing styles on scroll.
const header = document.getElementsByTagName("header")[0];

window.addEventListener("scroll", () => {
  if (window.scrollY === 0) {
    header.classList.add("header--on-top");
  } else {
    header.classList.remove("header--on-top");
  }
});

if (window.scrollY > 0) {
  document.getElementsByTagName("header")[0].classList.add("header--on-top");
}
